/* cureency section css start */
.cureency-section {
  padding: 50px 0;
  background-color: #000000; }
.cureency-item {
  position: relative;
  &:last-child {
    &::after {
      display: none; } }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    right: 0;
    height: 36px;
    margin-top: -18px;
    width: 2px;
    background-color: rgba($base-color, 0.75); } }
.cureency-card {
  &__title {
    font-size: 14px; }
  &__amount {
    font-size: 24px; } }
/* cureency section css end  */


/* about section css start */
.about-content {}
/* about section css end */


/* package section css start */
.package-card {
  padding: 30px;
  border: 3px solid $base-color;
  background-color: #000000;
  @include border-radius(8px);
  height: 100%;
  box-shadow: 0 0 15px rgba($base-color, 0.5);
  @include transition(all 0.3s);
  &:hover {
    box-shadow: 0 5px 15px rgba($base-color, 0.5);
    @include transform(translateY(-5px)); }
  &__title {
    font-size: 32px; }
  &__percantage {
    font-size: 24px;
    font-family: $heading-font;
    font-weight: 600; }
  &__features {
    li {
      padding: 10px 0;
      border-bottom: 1px solid rgba($base-color, 0.35); } }
  &__range {
    font-family: $heading-font;
    font-weight: 600;
    font-size: 24px; } }
/* package section css end */


/* choose us section css start */
.choose-card {
  padding: 30px;
  background-color: $base-color;
  background-color: rgba(#000000, 0.5);
  &__header {
    @include d-flex;
    align-items: center; }
  &__icon {
    width: 55px;
    i {
      font-size: 42px;
      line-height: 1; } }
  &__title {
    width: calc(100% - 55px); } }
/* choose us section css end */


/* profit calculator section css start */
.profit-calculator-wrapper {
  padding: 50px;
  border: 2px solid rgba($base-color, 0.5);
  @include border-radius(5px);
  background-color: #000000;
  box-shadow: 0 0 15px rgba($base-color, 0.5); }
/* profit calculator section css end */


/* how work section css start */
.work-item {
  position: relative;
  z-index: 1;
  &:last-child {
    &::before {
      display: none; } }
  &::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 85px;
    width: 450px;
    height: 2px;
    border-top: 1px dashed $base-color;
    z-index: -1; } }
.work-card {
  padding: 30px;
  &__icon {
    width: 110px;
    height: 110px;
    @include border-radius(50%);
    border: 3px solid $base-color;
    box-shadow: 0 0 15px 3px rgba($base-color, 0.65);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    position: relative;
    .step-number {
      position: absolute;
      top: -3px;
      right: -5px;
      width: 32px;
      height: 32px;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      border: 2px solid $base-color;
      @include border-radius(50%); }
    i {
      font-size: 44px;
      line-height: 1; } }
  &__content {
    margin-top: 35px; } }
/* how work section css end */


/* testimonial section css start */
.testimonial-card {
  background-color: rgba($base-color, 0.65);
  @include border-radius(5px);
  box-shadow: 0 5px 0px $base-color;
  &__content {
    padding: 30px 30px 60px 30px;
    border-bottom: 1px solid rgba($base-color, 0.5); }
  &__client {
    padding: 0 30px 20px 30px;
    margin-top: -38px;
    .thumb {
      width: 75px;
      height: 75px;
      overflow: hidden;
      border: 3px solid $base-color;
      @include border-radius(50%);
      margin-left: auto;
      img {
        width: inherit;
        height: inherit;
        @include object-fit; } }
    .name {
      font-size: 16px; }
    .designation {
      font-size: 14px; } } }
.ratings {
  i {
    color: #e4b332;
    font-size: 14px; } }
.testimonial-slider {
  .slick-list {
    margin: -15px; }
  .single-slide {
    margin: 15px; }
  .slick-dots {
    margin-top: 30px;
    @include d-flex;
    justify-content: center;
    li {
      margin: 0 5px;
      &.slick-active {
        button {
          background-color: $base-color;
          width: 35px; } }
      button {
        font-size: 0;
        width: 20px;
        height: 6px;
        background-color: rgba($base-color, 0.5);
        @include border-radius(999px);
        @include transition(all 0.3s); } } } }
/* testimonial section css end */


/* team section css start */
.team-card {
  background-color: #343A40;
  padding: 15px;
  @include transition(all 0.3s);
  @include border-radius(5px);
  &:hover {
    background-color: $base-color;
    box-shadow: 0 5px 10px 5px rgba($base-color, 0.5);
    @include transform(translateY(-5px)); }
  &__thumb {
    img {
      @include border-radius(5px); } }
  &__content {
    padding: 20px 15px 15px 15px; } }
/* team section css end */


/* data section css start */
#chart > * {
  color: #ffffff !important;
  fill: #ffffff !important; }
.apexcharts-xaxis-label {
  color: #ffffff !important; }
/* data section css end */


/* top investor section css start */
.border-top-1 {
  border-top: 1px solid rgba($base-color, 0.5); }
.investor-card {
  background-color: #343A40;
  padding: 15px;
  @include d-flex;
  @include transition(all 0.3s);
  &:hover {
    background-color: $base-color;
    box-shadow: 0 5px 10px 5px rgba($base-color, 0.5);
    @include transform(translateY(-5px)); }
  &__thumb {
    width: 35%;
    @include border-radius(5px); }
  &__content {
    width: 65%;
    padding: 15px 0 15px 20px; } }
/* top investor section css end */


/* cta section css start */
.cta-wrapper {
  padding: 35px 100px;
  box-shadow: 0 3px 15px rgba($base-color, 0.5); }
/* cta section css end */


/* payment brand section css start */
.brand-item {
  padding: 30px;
  @include d-flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border: 2px solid rgba($base-color, 0.5);
  @include border-radius(5px);
  img {
    max-height: 75px; } }
.payment-slider {
  .slick-list {
    margin: -15px; }
  .single-slide {
    margin: 15px; } }
/* payment brand section css end */


/* blog section css start */
.blog-card {
  padding: 15px;
  @include border-radius(8px);
  background-color: #000000;
  box-shadow: 0 0 0px 2px rgba($base-color, 0.8);
  &__thumb {
    img {
      @include border-radius(5px); } }
  &__content {
    padding: 20px 15px 15px 15px; }
  &__meta {
    margin: -5px -7px;
    li {
      margin: 5px 7px;
      i {
        color: $base-color; }
      a {
        color: $base-color; } } } }
/* blog section css end */


/* subscribe section css start */
.subscribe-wrapper {
  padding: 50px;
  @include border-radius(8px);
  box-shadow: 0 0 10px rgba($base-color, 0.5); }
.subscribe-form {
  @include d-flex;
  .form-control {
    width: calc(100% - 65px);
    border-right: none; }
  .subscribe-btn {
    width: 65px;
    background-color: $base-color;
    color: #000000;
    @include border-radius(0 3px 3px 0);
    i {
      font-size: 24px;
      line-height: 1; } } }
/* subscribe section css end */
