/* reset css start */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&family=Exo&family=Playfair+Display&display=swap");
html {
  scroll-behavior: smooth; }

body {
  position: relative;
  font-family: "Exo", sans-serif;
  color: #ffffff;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  background-color: #101113; }

img {
  max-width: 100%;
  height: auto; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }

button {
  cursor: pointer; }

*:focus {
  outline: none; }

button {
  border: none; }

button:focus {
  outline: none; }

span {
  display: inline-block; }

a:hover {
  color: #cca354; }

/* reset css end */
/* global css strat */
@media (min-width: 1200px) {
  .container {
    max-width: 1360px; } }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-80 {
  margin-top: 80px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-115 {
  margin-bottom: 115px; }

.mb-none-30 {
  margin-bottom: -30px; }

.mb-none-40 {
  margin-bottom: -40px; }

.mb-none-50 {
  margin-bottom: -50px; }

.mt-minus-100 {
  margin-top: -100px; }

.mb-none-115 {
  margin-bottom: -115px; }

.pt-60 {
  padding-top: 60px; }

.pb-60 {
  padding-bottom: 60px; }

.pt-120 {
  padding-top: 120px; }
  @media (max-width: 991px) {
    .pt-120 {
      padding-top: 90px; } }
  @media (max-width: 575px) {
    .pt-120 {
      padding-top: 80px; } }

.pb-120 {
  padding-bottom: 120px; }
  @media (max-width: 991px) {
    .pb-120 {
      padding-bottom: 90px; } }
  @media (max-width: 575px) {
    .pb-120 {
      padding-bottom: 80px; } }

.pt-150 {
  padding-top: 150px; }
  @media (max-width: 1199px) {
    .pt-150 {
      padding-top: 120px; } }
  @media (max-width: 991px) {
    .pt-150 {
      padding-top: 90px; } }
  @media (max-width: 575px) {
    .pt-150 {
      padding-top: 80px; } }

.pb-150 {
  padding-bottom: 150px; }
  @media (max-width: 1199px) {
    .pb-150 {
      padding-bottom: 120px; } }
  @media (max-width: 991px) {
    .pb-150 {
      padding-bottom: 90px; } }
  @media (max-width: 575px) {
    .pb-150 {
      padding-bottom: 80px; } }

.mt-100 {
  margin-top: 100px; }

.bg_img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.background-position-y-top {
  background-position-y: top !important; }

.background-position-y-bottom {
  background-position-y: bottom !important; }

.background-position-y-center {
  background-position-y: center !important; }

.base--bg {
  background-color: #cca354 !important; }

.base--bg-two {
  background-color: #062c4e !important; }

.bg--1 {
  background-color: #f05454 !important; }

.bg--2 {
  background-color: #403DEE !important; }

.bg--3 {
  background-color: #ED1568 !important; }

.bg--4 {
  background-color: #403DEE !important; }

.border-radius--5 {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important; }

.border-radius--10 {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important; }

.z-index-2 {
  z-index: 2; }

.overlay--one {
  position: relative;
  z-index: 9; }
  .overlay--one::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.75;
    z-index: -1; }

.overlay--radial {
  position: relative;
  z-index: 9; }
  .overlay--radial::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#000000, rgba(0, 0, 0, 0.5));
    opacity: 0.95;
    z-index: -1; }

.base--color {
  color: #cca354 !important; }

.p--color {
  color: #ffffff !important; }

.h--color {
  color: #ffffff !important; }

.nice-select {
  float: none;
  width: 100%;
  min-height: 50px;
  line-height: 50px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; }
  .nice-select .list {
    width: 100%;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15); }

.para-white {
  color: #e6e6e6 !important; }

.heading--color {
  color: #363636 !important; }

.section--bg {
  background-color: #f9f9f9; }

.section--img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 450px;
  z-index: -1; }

.bg--base {
  background-color: #cca354 !important; }

.text-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }

.slick-arrow {
  cursor: pointer; }

.section-header {
  margin-bottom: 55px;
  margin-top: -8px; }
  @media (max-width: 991px) {
    .section-header {
      margin-bottom: 50px; } }

.section-top-title {
  font-size: 18px;
  font-family: "Exo", sans-serif;
  color: #cca354;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .section-top-title {
      font-size: 20px; } }
  @media (max-width: 575px) {
    .section-top-title {
      font-size: 18px; } }
  @media (max-width: 575px) {
    .section-top-title {
      font-size: 16px; } }

.section-title {
  font-size: 44px;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .section-title {
      font-size: 28px; } }
  @media (max-width: 440px) {
    .section-title {
      font-size: 24px; } }
  .section-title.has--border {
    position: relative;
    padding-bottom: 10px; }
    .section-title.has--border::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      width: 50px;
      margin-left: -25px;
      height: 2px;
      background-color: #cca354; }

.section-title + p {
  margin-top: 15px; }

.box-header .title {
  font-size: 32px; }
  @media (max-width: 320px) {
    .box-header .title {
      font-size: 28px; } }

.border-radius-100 {
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important; }

.has-link {
  position: relative; }

.item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.text-white * {
  color: #ffffff !important; }

.hover--effect-1 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0px 25px 40px 0px rgba(51, 51, 51, 0.1); }
  .hover--effect-1:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0px 0px 15px 0px rgba(51, 51, 51, 0.05); }

.video-button {
  position: absolute;
  width: 105px;
  height: 105px;
  color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #cca354;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  animation: pulse 2000ms linear infinite;
  -webkit-animation: pulse 2000ms linear infinite;
  -moz-animation: pulse 2000ms linear infinite; }
  .video-button:hover {
    color: #ffffff; }
  .video-button::before, .video-button::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 74px;
    background-color: #cca354;
    opacity: 0.15;
    z-index: -10; }
  .video-button::before {
    z-index: -10;
    animation: inner-ripple 2000ms linear infinite;
    -webkit-animation: inner-ripple 2000ms linear infinite;
    -moz-animation: inner-ripple 2000ms linear infinite; }
  .video-button::after {
    z-index: -10;
    animation: outer-ripple 2000ms linear infinite;
    -webkit-animation: outer-ripple 2000ms linear infinite;
    -moz-animation: outer-ripple 2000ms linear infinite; }
  .video-button i {
    font-size: 32px; }

@-webkit-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; }
  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0; } }

@-moz-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; }
  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0; } }

@-ms-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; }
  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0; } }

@keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; }
  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0; } }

@-webkit-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; } }

@-moz-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; } }

@-ms-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; } }

@keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; } }

.text-sm {
  font-size: 12px !important; }

.text-md {
  font-size: 14px !important; }

.card {
  box-shadow: 0 10px 65px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }
  .card .card-header {
    background-color: #ffffff; }
  .card.style--two {
    border: none; }
    .card.style--two > .card-header {
      background-color: #001d4a; }

.badge {
  font-weight: 400;
  padding: 4px 10px; }

.nav-tabs {
  padding: 0 30px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .nav-tabs.custom--style {
    background-color: transparent; }
    .nav-tabs.custom--style .nav-item .nav-link {
      padding: 15px 20px;
      border: none;
      border-bottom: 2px solid transparent;
      color: #ffffff;
      font-size: 14px;
      text-transform: uppercase;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
      .nav-tabs.custom--style .nav-item .nav-link.active {
        border-color: #cca354;
        color: #cca354; }
  .nav-tabs.custom--style-two {
    background-color: transparent;
    border: none; }
    .nav-tabs.custom--style-two .nav-item {
      margin: 5px 10px; }
      .nav-tabs.custom--style-two .nav-item .nav-link {
        padding: 8px 20px;
        border: 1px solid rgba(204, 163, 84, 0.45);
        color: #ffffff;
        font-size: 14px;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; }
        .nav-tabs.custom--style-two .nav-item .nav-link.active {
          border-color: #cca354;
          color: #ffffff;
          background-color: #cca354; }

.cmn-list {
  margin-top: 20px; }
  .cmn-list li + li {
    margin-top: 15px; }
  .cmn-list li {
    position: relative;
    padding-left: 40px; }
    .cmn-list li::before {
      position: absolute;
      content: '';
      top: 5px;
      left: 0;
      width: 20px;
      height: 10px;
      border-left: 1px solid #cca354;
      border-bottom: 1px solid #cca354;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }

.cmn-list-2 {
  margin-top: 20px; }
  .cmn-list-2 li + li {
    margin-top: 10px; }
  .cmn-list-2 li {
    position: relative;
    padding-left: 45px; }
    .cmn-list-2 li:nth-last-of-type(3n+3)::before {
      border-color: #a22546; }
    .cmn-list-2 li:nth-last-of-type(3n+2)::before {
      border-color: #f7a139; }
    .cmn-list-2 li:nth-last-of-type(3n+1)::before {
      border-color: #3097d1; }
    .cmn-list-2 li::before {
      position: absolute;
      content: '';
      top: 5px;
      left: 0;
      width: 20px;
      height: 10px;
      border-left: 1px solid red;
      border-bottom: 1px solid red;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }

.number-list--style {
  list-style-type: decimal;
  padding-left: 15px; }
  .number-list--style li + li {
    margin-top: 10px; }
  .number-list--style li span {
    font-weight: 500;
    color: #cca354; }

.disc-list--style {
  list-style-type: disc;
  padding-left: 15px; }
  .disc-list--style li + li {
    margin-top: 10px; }

.pagination {
  margin-top: 50px; }
  .pagination li + li {
    margin-left: 10px; }
  .pagination li.active a {
    background-color: #cca354;
    border-color: #cca354;
    color: #ffffff; }
  .pagination li a {
    border: 1px solid rgba(204, 163, 84, 0.45);
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -ms-border-radius: 3px !important;
    -o-border-radius: 3px !important;
    padding: 12px 20px;
    background-color: transparent;
    color: #ffffff;
    font-weight: 700; }
    .pagination li a:hover {
      background-color: #cca354;
      border-color: #cca354;
      color: #ffffff; }

.cmn-accordion .card + .card {
  margin-top: 15px; }

.cmn-accordion .card {
  border: none;
  background-color: #000000;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1); }

.cmn-accordion .card-header {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0 !important;
  border: 1px solid rgba(204, 163, 84, 0.45);
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important; }
  .cmn-accordion .card-header .btn {
    background-color: #cca354;
    padding: 15px 20px;
    display: inline-flex;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    .cmn-accordion .card-header .btn.collapsed {
      background-color: #343A40; }
    .cmn-accordion .card-header .btn:hover, .cmn-accordion .card-header .btn:focus {
      text-decoration: none;
      box-shadow: none; }
    .cmn-accordion .card-header .btn i {
      color: #ffffff;
      font-size: 24px; }
    .cmn-accordion .card-header .btn span {
      color: #ffffff;
      font-size: 16px;
      padding-left: 15px; }
      @media (max-width: 767px) {
        .cmn-accordion .card-header .btn span {
          font-size: 16px; } }

.cmn-accordion .card-body {
  border-top: none;
  padding: 20px 30px;
  background-color: #343A40; }

blockquote p {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff; }

input:focus,
textarea:focus,
.nice-select.open {
  border-color: #cca354; }

.page-breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 15px; }
  .page-breadcrumb li {
    color: rgba(255, 255, 255, 0.8);
    text-transform: capitalize; }
    .page-breadcrumb li::after {
      content: '-';
      color: #ffffff;
      margin: 0 5px; }
    .page-breadcrumb li:first-child::before {
      content: "\f015";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #cca354;
      margin-right: 6px; }
    .page-breadcrumb li:last-child::after {
      display: none; }
    .page-breadcrumb li a {
      color: #ffffff;
      text-transform: capitalize; }
      .page-breadcrumb li a:hover {
        color: #cca354; }

.scroll-to-top {
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 99999;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  background-color: #cca354;
  line-height: 77px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25); }
  .scroll-to-top .scroll-icon {
    font-size: 31px;
    color: #ffffff;
    display: inline-block; }
    .scroll-to-top .scroll-icon i {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }

a[class*='lightcase-icon-'].lightcase-icon-close {
  top: 100px;
  right: 50px; }

@-webkit-keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-moz-keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-ms-keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  30% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-moz-keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  30% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-ms-keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  30% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  30% {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1); } }

.preloader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
  text-align: center; }
  .preloader .preloader-container {
    display: inline-block;
    width: 100px;
    height: 100px;
    position: relative; }
  .preloader .animated-preloader {
    display: inline-block;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: #cca354;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; }
    .preloader .animated-preloader::after {
      content: '';
      display: inline-block;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background: white;
      -webkit-animation: preloader-inside-white 1s ease-in-out infinite;
      -ms-animation: preloader-inside-white 1s ease-in-out infinite;
      animation: preloader-inside-white 1s ease-in-out infinite; }
    .preloader .animated-preloader::before {
      content: '';
      display: inline-block;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background: #cca354;
      -webkit-animation: preloader-inside-red 1s ease-in-out infinite;
      -ms-animation: preloader-inside-red 1s ease-in-out infinite;
      animation: preloader-inside-red 1s ease-in-out infinite; }

a[class*='lightcase-icon-'].lightcase-icon-close {
  top: 100px;
  right: 50px;
  z-index: 9999999999; }

#lightcase-overlay {
  z-index: 99999999; }

#lightcase-case {
  z-index: 999999999; }

/* global css end */
h1 {
  font-size: 62px; }

h2 {
  font-size: 32px; }
  @media (max-width: 991px) {
    h2 {
      font-size: 30px; } }
  @media (max-width: 575px) {
    h2 {
      font-size: 28px; } }

h3 {
  font-size: 24px; }

h4 {
  font-size: 22px; }
  @media (max-width: 767px) {
    h4 {
      font-size: 20px; } }

h5 {
  font-size: 20px; }
  @media (max-width: 767px) {
    h5 {
      font-size: 18px; } }

h6 {
  font-size: 18px; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Josefin Sans", sans-serif;
  color: #ffffff;
  font-weight: 600;
  margin: 0;
  line-height: 1.3; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  font-family: "Josefin Sans", sans-serif;
  color: #ffffff;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 1.3; }

p, li, span {
  margin: 0; }

a {
  text-decoration: none;
  display: inline-block;
  font-family: "Exo", sans-serif;
  font-weight: 400; }

a:hover {
  text-decoration: none; }

.h-font-family {
  font-family: "Josefin Sans", sans-serif !important; }

.p-font-family {
  font-family: "Josefin Sans", sans-serif !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.text-small {
  font-size: 12px !important; }

.f-size-14 {
  font-size: 14px !important; }

.f-size-18 {
  font-size: 18px !important; }

/* table css start */
.table.white-space-nowrap {
  white-space: nowrap; }

.table.style--two {
  background-color: #000000;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 5px 0 rgba(204, 163, 84, 0.25); }
  .table.style--two thead {
    background-color: #cca354; }
    .table.style--two thead tr th {
      border-top: none;
      border-bottom: none;
      color: #ffffff;
      text-align: center;
      padding: 10px 20px; }
      .table.style--two thead tr th:first-child {
        text-align: left; }
      .table.style--two thead tr th:last-child {
        text-align: right; }
  .table.style--two tr th, .table.style--two tr td {
    font-size: 14px;
    border-top-color: rgba(204, 163, 84, 0.25); }
  .table.style--two tbody tr td {
    text-align: center;
    color: #ffffff;
    padding: 15px 20px;
    vertical-align: middle;
    font-size: 15px; }
    .table.style--two tbody tr td:first-child {
      text-align: left; }
    .table.style--two tbody tr td:last-child {
      text-align: right; }
  .table.style--two tbody tr:first-child td {
    border-top: none; }

.table .user {
  display: flex;
  align-items: center; }
  .table .user .thumb {
    width: 45px;
    height: 45px;
    border: 2px solid #ffffff;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    margin-right: 10px; }
    .table .user .thumb img {
      width: inherit;
      height: inherit; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #6e41ff0f; }

.badge {
  font-weight: 400;
  padding: 4px 10px; }

.icon-btn {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  font-size: 14px; }

[data-label] {
  position: relative; }
  [data-label]::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: #000000;
    top: 0;
    left: 0;
    padding: 13px 15px;
    display: none;
    font-size: 12px; }

@media (max-width: 1199px) {
  .table-responsive--lg thead {
    display: none; }
  .table-responsive--lg tbody tr:nth-child(odd) {
    background-color: #f5f5f5; }
  .table-responsive--lg tr th, .table-responsive--lg tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-responsive--lg .user {
    justify-content: flex-end; }
  .table-responsive--lg [data-label]::before {
    display: block; } }

@media (max-width: 991px) {
  .table-responsive--md thead {
    display: none; }
  .table-responsive--md tbody tr:nth-child(odd) {
    background-color: #f5f5f5; }
  .table-responsive--md tr th, .table-responsive--md tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-responsive--md .user {
    justify-content: flex-end; }
  .table-responsive--md [data-label]::before {
    display: block; } }

@media (max-width: 767px) {
  .table-responsive--sm thead {
    display: none; }
  .table-responsive--sm tbody tr:nth-child(odd) {
    background-color: #f5f5f5; }
  .table-responsive--sm tr th, .table-responsive--sm tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-responsive--sm .user {
    justify-content: flex-end; }
  .table-responsive--sm [data-label]::before {
    display: block; } }

@media (max-width: 575px) {
  .table-responsive--xs thead {
    display: none; }
  .table-responsive--xs tbody tr:nth-child(odd) {
    background-color: #f5f5f5; }
  .table-responsive--xs tr th, .table-responsive--xs tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-responsive--xs .user {
    justify-content: flex-end; }
  .table-responsive--xs [data-label]::before {
    display: block; } }

@media (max-width: 1199px) {
  *[class*="table-responsive--"].data-label--none tr th, *[class*="table-responsive--"].data-label--none tr td {
    padding-left: .75rem; } }

/* table css end */
/* button css start */
.cmn-btn {
  padding: 12px 35px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #cca354;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #000000; }
  .cmn-btn:hover {
    color: #000000;
    background-color: #b78b36; }
  .cmn-btn.active {
    background-color: #001d4a; }

.cmn-btn2 {
  padding: 12px 35px;
  text-transform: uppercase;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #062c4e;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #000000; }
  .cmn-btn2:hover {
    color: #000000;
    background-color: #c69840; }

.border-btn {
  padding: 14px 35px;
  font-size: 16px;
  font-weight: weight("semi");
  text-transform: capitalize;
  color: #ffffff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #e5e5e5;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff; }
  .border-btn:hover {
    background-color: #cca354;
    color: #000000;
    border-color: #cca354;
    box-shadow: 0px 25px 40px 0px rgba(51, 51, 51, 0.1); }

.read-btn {
  padding: 10px 20px;
  min-width: 130px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: rgba(204, 163, 84, 0.14);
  color: #cca354;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .read-btn:hover {
    background-color: #cca354;
    color: #ffffff; }

.btn-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  .btn-group *[class*="btn"] {
    margin: 5px 10px;
    align-items: center; }
    .btn-group *[class*="btn"].d-flex {
      padding: 8px 35px; }

[class*="btn"].btn-md {
  padding: 8px 30px; }

[class*="btn"].btn-sm {
  padding: 6px 20px; }

.action-btn {
  padding: 5px 15px;
  font-weight: 600;
  background-color: #ffffff;
  color: #cca354;
  font-size: 14px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }

.bg-btn {
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 12px 25px; }
  .bg-btn:hover {
    background-color: #001d4a;
    color: #000000;
    border-color: #001d4a; }

/* button css end */
/* form css start */
.form-control {
  padding: 10px 20px;
  border: 1px solid rgba(204, 163, 84, 0.45);
  width: 100%;
  background-color: transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  color: #ffffff;
  min-height: 50px; }
  .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .form-control:-moz-placeholder {
    color: rgba(255, 255, 255, 0.7); }
  .form-control:focus {
    box-shadow: 0 2px 5px rgba(204, 163, 84, 0.5);
    border-color: #cca354;
    background-color: transparent;
    color: #ffffff; }

select {
  padding: 10px 20px;
  width: 100%;
  border: 1px solid rgba(204, 163, 84, 0.45);
  cursor: pointer;
  color: #ffffff;
  background-color: transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  height: 50px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px; }
  select option {
    background-color: #cca354;
    padding: 10px 0;
    display: block;
    border-top: 1px solid #e5e5e5; }

textarea {
  min-height: 150px !important;
  resize: none;
  width: 100%; }

label {
  color: #cccccc;
  margin-bottom: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 14px;
  font-weight: 600; }

.nice-select {
  float: none;
  background-color: #fafafa;
  border: 1px solid rgba(204, 163, 84, 0.45); }

.custom-checkbox {
  display: flex;
  align-items: center; }
  .custom-checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer; }
  .custom-checkbox label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0; }
    .custom-checkbox label::before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #e5e5e5;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 6px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 8px; }

.custom-checkbox input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 5px;
  height: 8px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); }

/* form css end*/
/* keyframes css start */
@-webkit-keyframes customRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

@-moz-keyframes customRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

@-ms-keyframes customRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes customRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

@-webkit-keyframes customaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); }
  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1); }
  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); } }

@-moz-keyframes customaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); }
  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1); }
  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); } }

@-ms-keyframes customaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); }
  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1); }
  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); } }

@keyframes customaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); }
  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1); }
  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05); }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); } }

@-webkit-keyframes customLight {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.6; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }

@-moz-keyframes customLight {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.6; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }

@-ms-keyframes customLight {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.6; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }

@keyframes customLight {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.6; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }

@-webkit-keyframes CustomBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  25% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  50% {
    -webkit-transform: translateY(-10);
    -ms-transform: translateY(-10);
    transform: translateY(-10); }
  75% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes CustomBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  25% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  50% {
    -webkit-transform: translateY(-10);
    -ms-transform: translateY(-10);
    transform: translateY(-10); }
  75% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes CustomBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  25% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  50% {
    -webkit-transform: translateY(-10);
    -ms-transform: translateY(-10);
    transform: translateY(-10); }
  75% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes CustomBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  25% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  50% {
    -webkit-transform: translateY(-10);
    -ms-transform: translateY(-10);
    transform: translateY(-10); }
  75% {
    -webkit-transform: translateY(-5);
    -ms-transform: translateY(-5);
    transform: translateY(-5); }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

/* keyframes css end */
/* header start */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; }
  .header.menu-fixed .header__top {
    display: none; }
  .header.menu-fixed .header__bottom {
    background-color: #000000; }
  .header__bottom {
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    @media (max-width: 1199px) {
      .header__bottom {
        padding: 10px 0; } }
  .header .site-logo img {
    width: 175px; }
    @media (max-width: 1199px) {
      .header .site-logo img {
        max-width: 150px; } }
  .header .main-menu {
    margin-left: 70px; }
    @media (max-width: 1199px) {
      .header .main-menu {
        margin-left: 0;
        padding: 15px 0; } }
    .header .main-menu li {
      position: relative; }
      .header .main-menu li:last-child::after {
        display: none; }
      .header .main-menu li.menu_has_children {
        position: relative; }
        .header .main-menu li.menu_has_children.open .sub-menu {
          display: block; }
        @media (max-width: 1199px) {
          .header .main-menu li.menu_has_children > a {
            display: block; } }
        .header .main-menu li.menu_has_children > a::before {
          position: absolute;
          content: "\f107";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          top: 0;
          right: 0;
          color: #ffffff;
          -webkit-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          display: none; }
          @media (max-width: 1199px) {
            .header .main-menu li.menu_has_children > a::before {
              display: block;
              top: 9px; } }
        .header .main-menu li.menu_has_children:hover > a::before {
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
          color: #cca354; }
      .header .main-menu li a {
        padding: 30px 15px 30px 0;
        text-transform: capitalize;
        font-size: 16px;
        color: #ffffff;
        position: relative; }
        @media (max-width: 1199px) {
          .header .main-menu li a {
            color: #ffffff;
            padding: 8px 0;
            display: block; } }
        .header .main-menu li a:hover, .header .main-menu li a:focus {
          color: #cca354; }
      .header .main-menu li .sub-menu {
        position: absolute;
        width: 220px;
        top: 105%;
        left: -20px;
        z-index: 9999;
        background-color: #cca354;
        padding: 10px 0;
        -webkit-box-shadow: 0px 18px 54px -8px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 18px 54px -8px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        border: 1px solid #e5e5e5; }
        @media (max-width: 1199px) {
          .header .main-menu li .sub-menu {
            opacity: 1;
            visibility: visible;
            display: none;
            position: static;
            -webkit-transition: none;
            -o-transition: none;
            transition: none;
            width: 100%; } }
        .header .main-menu li .sub-menu li {
          border-bottom: 1px dashed rgba(255, 255, 255, 0.35); }
          .header .main-menu li .sub-menu li:last-child {
            border-bottom: none; }
          .header .main-menu li .sub-menu li a {
            padding: 8px 20px;
            display: block;
            color: #ffffff;
            font-size: 14px; }
            .header .main-menu li .sub-menu li a:hover {
              background-color: rgba(255, 255, 255, 0.05);
              color: #ffffff;
              text-decoration: underline; }
        .header .main-menu li .sub-menu li + li {
          margin-left: 0; }
      .header .main-menu li:hover .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible; }
    .header .main-menu li + li {
      margin-left: 20px; }
      @media (max-width: 1199px) {
        .header .main-menu li + li {
          margin-left: 0; } }
  .header .nav-right {
    margin-left: 30px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; }
    @media (max-width: 1199px) {
      .header .nav-right {
        margin-left: 0; } }

@media (max-width: 1199px) {
  .navbar-collapse {
    background-color: #001d4a;
    padding: 0 30px 20px 30px; } }

@media (max-width: 767px) {
  .navbar-collapse {
    max-height: 320px;
    overflow: auto; } }

.navbar-toggler {
  padding: 0;
  border: none; }
  .navbar-toggler:focus {
    outline: none; }

.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: 35px;
  height: 20px;
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #ffffff;
  font-size: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer; }
  @media (max-width: 1199px) {
    .menu-toggle {
      color: #ffffff; } }

.menu-toggle:before, .menu-toggle:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out; }
  @media (max-width: 1199px) {
    .menu-toggle:before, .menu-toggle:after {
      background-color: #ffffff; } }

span.is-active {
  border-color: transparent; }

span.is-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg); }

span.is-active:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg); }

span.menu-toggle:hover {
  color: #ffffff; }
  @media (max-width: 1199px) {
    span.menu-toggle:hover {
      color: #ffffff; } }

span.is-active {
  border-color: transparent; }

span.is-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg); }

span.is-active:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg); }

/* header end */
/* hero css start */
.hero {
  padding-top: 270px;
  padding-bottom: 210px; }
  .hero__title {
    font-size: 58px; }

/* hero css end */
/* cureency section css start */
.cureency-section {
  padding: 50px 0;
  background-color: #000000; }

.cureency-item {
  position: relative; }
  .cureency-item:last-child::after {
    display: none; }
  .cureency-item::after {
    position: absolute;
    content: '';
    top: 50%;
    right: 0;
    height: 36px;
    margin-top: -18px;
    width: 2px;
    background-color: rgba(204, 163, 84, 0.75); }

.cureency-card__title {
  font-size: 14px; }

.cureency-card__amount {
  font-size: 24px; }

/* cureency section css end  */
/* about section css start */
/* about section css end */
/* package section css start */
.package-card {
  padding: 30px;
  border: 3px solid #cca354;
  background-color: #000000;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  height: 100%;
  box-shadow: 0 0 15px rgba(204, 163, 84, 0.5);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .package-card:hover {
    box-shadow: 0 5px 15px rgba(204, 163, 84, 0.5);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); }
  .package-card__title {
    font-size: 32px; }
  .package-card__percantage {
    font-size: 24px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600; }
  .package-card__features li {
    padding: 10px 0;
    border-bottom: 1px solid rgba(204, 163, 84, 0.35); }
  .package-card__range {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    font-size: 24px; }

/* package section css end */
/* choose us section css start */
.choose-card {
  padding: 30px;
  background-color: #cca354;
  background-color: rgba(0, 0, 0, 0.5); }
  .choose-card__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; }
  .choose-card__icon {
    width: 55px; }
    .choose-card__icon i {
      font-size: 42px;
      line-height: 1; }
  .choose-card__title {
    width: calc(100% - 55px); }

/* choose us section css end */
/* profit calculator section css start */
.profit-calculator-wrapper {
  padding: 50px;
  border: 2px solid rgba(204, 163, 84, 0.5);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #000000;
  box-shadow: 0 0 15px rgba(204, 163, 84, 0.5); }

/* profit calculator section css end */
/* how work section css start */
.work-item {
  position: relative;
  z-index: 1; }
  .work-item:last-child::before {
    display: none; }
  .work-item::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 85px;
    width: 450px;
    height: 2px;
    border-top: 1px dashed #cca354;
    z-index: -1; }

.work-card {
  padding: 30px; }
  .work-card__icon {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 3px solid #cca354;
    box-shadow: 0 0 15px 3px rgba(204, 163, 84, 0.65);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    position: relative; }
    .work-card__icon .step-number {
      position: absolute;
      top: -3px;
      right: -5px;
      width: 32px;
      height: 32px;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      border: 2px solid #cca354;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%; }
    .work-card__icon i {
      font-size: 44px;
      line-height: 1; }
  .work-card__content {
    margin-top: 35px; }

/* how work section css end */
/* testimonial section css start */
.testimonial-card {
  background-color: rgba(204, 163, 84, 0.65);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: 0 5px 0px #cca354; }
  .testimonial-card__content {
    padding: 30px 30px 60px 30px;
    border-bottom: 1px solid rgba(204, 163, 84, 0.5); }
  .testimonial-card__client {
    padding: 0 30px 20px 30px;
    margin-top: -38px; }
    .testimonial-card__client .thumb {
      width: 75px;
      height: 75px;
      overflow: hidden;
      border: 3px solid #cca354;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      margin-left: auto; }
      .testimonial-card__client .thumb img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        -o-object-fit: cover;
        object-position: center;
        -o-object-position: center; }
    .testimonial-card__client .name {
      font-size: 16px; }
    .testimonial-card__client .designation {
      font-size: 14px; }

.ratings i {
  color: #e4b332;
  font-size: 14px; }

.testimonial-slider .slick-list {
  margin: -15px; }

.testimonial-slider .single-slide {
  margin: 15px; }

.testimonial-slider .slick-dots {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center; }
  .testimonial-slider .slick-dots li {
    margin: 0 5px; }
    .testimonial-slider .slick-dots li.slick-active button {
      background-color: #cca354;
      width: 35px; }
    .testimonial-slider .slick-dots li button {
      font-size: 0;
      width: 20px;
      height: 6px;
      background-color: rgba(204, 163, 84, 0.5);
      border-radius: 999px;
      -webkit-border-radius: 999px;
      -moz-border-radius: 999px;
      -ms-border-radius: 999px;
      -o-border-radius: 999px;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }

/* testimonial section css end */
/* team section css start */
.team-card {
  background-color: #343A40;
  padding: 15px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .team-card:hover {
    background-color: #cca354;
    box-shadow: 0 5px 10px 5px rgba(204, 163, 84, 0.5);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); }
  .team-card__thumb img {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px; }
  .team-card__content {
    padding: 20px 15px 15px 15px; }

/* team section css end */
/* data section css start */
#chart > * {
  color: #ffffff !important;
  fill: #ffffff !important; }

.apexcharts-xaxis-label {
  color: #ffffff !important; }

/* data section css end */
/* top investor section css start */
.border-top-1 {
  border-top: 1px solid rgba(204, 163, 84, 0.5); }

.investor-card {
  background-color: #343A40;
  padding: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .investor-card:hover {
    background-color: #cca354;
    box-shadow: 0 5px 10px 5px rgba(204, 163, 84, 0.5);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); }
  .investor-card__thumb {
    width: 35%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px; }
  .investor-card__content {
    width: 65%;
    padding: 15px 0 15px 20px; }

/* top investor section css end */
/* cta section css start */
.cta-wrapper {
  padding: 35px 100px;
  box-shadow: 0 3px 15px rgba(204, 163, 84, 0.5); }

/* cta section css end */
/* payment brand section css start */
.brand-item {
  padding: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border: 2px solid rgba(204, 163, 84, 0.5);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .brand-item img {
    max-height: 75px; }

.payment-slider .slick-list {
  margin: -15px; }

.payment-slider .single-slide {
  margin: 15px; }

/* payment brand section css end */
/* blog section css start */
.blog-card {
  padding: 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: #000000;
  box-shadow: 0 0 0px 2px rgba(204, 163, 84, 0.8); }
  .blog-card__thumb img {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px; }
  .blog-card__content {
    padding: 20px 15px 15px 15px; }
  .blog-card__meta {
    margin: -5px -7px; }
    .blog-card__meta li {
      margin: 5px 7px; }
      .blog-card__meta li i {
        color: #cca354; }
      .blog-card__meta li a {
        color: #cca354; }

/* blog section css end */
/* subscribe section css start */
.subscribe-wrapper {
  padding: 50px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0 0 10px rgba(204, 163, 84, 0.5); }

.subscribe-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .subscribe-form .form-control {
    width: calc(100% - 65px);
    border-right: none; }
  .subscribe-form .subscribe-btn {
    width: 65px;
    background-color: #cca354;
    color: #000000;
    border-radius: 0 3px 3px 0;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    -o-border-radius: 0 3px 3px 0; }
    .subscribe-form .subscribe-btn i {
      font-size: 24px;
      line-height: 1; }

/* subscribe section css end */
/* sidebar css start */
.search-from .custom-input-field {
  position: relative; }
  .search-from .custom-input-field input {
    width: 100%;
    height: 60px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0; }
    .search-from .custom-input-field input:focus {
      border-color: #cca354; }
  .search-from .custom-input-field i {
    position: absolute;
    top: 21px;
    right: 20px;
    color: #cca354;
    font-size: 18px; }

@media (max-width: 991px) {
  .sidebar {
    margin-top: 65px; } }

.sidebar .widget + .widget {
  margin-top: 50px; }
  @media (max-width: 991px) {
    .sidebar .widget + .widget {
      margin-top: 40px; } }

.sidebar .widget-title {
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 30px;
  position: relative;
  padding-left: 15px; }
  .sidebar .widget-title::before {
    position: absolute;
    content: '';
    top: 4px;
    left: 0;
    width: 5px;
    height: 23px;
    background-color: #cca354; }

.sidebar .category-list {
  margin-top: -7px; }
  .sidebar .category-list li {
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5; }
    .sidebar .category-list li:first-child {
      padding-top: 0; }
    .sidebar .category-list li:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .sidebar .category-list li a {
      text-transform: capitalize;
      display: block;
      color: #ffffff; }
      .sidebar .category-list li a:hover {
        color: #cca354; }

.sidebar .destination-filter-widget {
  padding: 50px 30px;
  background-color: #eaf1fd;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .sidebar .destination-filter-widget .widget-title {
    margin-bottom: 21px;
    padding-left: 0; }
    .sidebar .destination-filter-widget .widget-title::before {
      display: none; }
  .sidebar .destination-filter-widget .form-group, .sidebar .destination-filter-widget .nice-select {
    margin-bottom: 20px; }
  .sidebar .destination-filter-widget .nice-select, .sidebar .destination-filter-widget input {
    background-color: #f5f8fe; }
  .sidebar .destination-filter-widget label {
    font-weight: 500; }
  .sidebar .destination-filter-widget .cmn-btn {
    padding: 10px 25px;
    font-size: 14px; }

.sidebar .sidebar-destination-form .ui-widget {
  background-color: #b2cdfb;
  border: none;
  height: 3px; }
  .sidebar .sidebar-destination-form .ui-widget .ui-slider-range {
    background-color: #cca354; }

.sidebar .sidebar-destination-form span.ui-slider-handle {
  width: 14px;
  height: 14px;
  background-color: #cca354;
  border: none;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%; }
  .sidebar .sidebar-destination-form span.ui-slider-handle:first-child {
    display: none; }

.sidebar .sidebar-destination-form .filter-price-result {
  margin-top: -47px; }
  .sidebar .sidebar-destination-form .filter-price-result input {
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0;
    text-align: right; }

.sidebar .sidebar-destination-form .cmn-btn-border {
  margin-top: 30px; }

.sidebar .tag-list {
  margin: -3px -1px; }
  .sidebar .tag-list a {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
    font-weight: 500;
    text-transform: capitalize;
    margin: 3px 1px;
    color: #ffffff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    .sidebar .tag-list a:hover {
      background-color: #cca354;
      color: #ffffff;
      border-color: #cca354; }

.sidebar .help-widget {
  padding: 50px 30px;
  background-color: #eaf1fd;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .sidebar .help-widget .widget-title {
    margin-bottom: 20px;
    padding-left: 0; }
    .sidebar .help-widget .widget-title::before {
      display: none; }
  .sidebar .help-widget .cmn-btn {
    padding: 10px 25px;
    font-size: 14px;
    margin-top: 20px; }

.upcoming-destination-list .upcoming-destination-single {
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .upcoming-destination-list .upcoming-destination-single:first-child {
    padding-top: 0; }
  .upcoming-destination-list .upcoming-destination-single:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .upcoming-destination-list .upcoming-destination-single .thumb {
    width: 65px; }
  .upcoming-destination-list .upcoming-destination-single .content {
    width: calc(100% - 65px);
    padding-left: 15px; }
    .upcoming-destination-list .upcoming-destination-single .content .destination-name {
      text-transform: capitalize; }
      .upcoming-destination-list .upcoming-destination-single .content .destination-name i {
        color: #b7d2ff;
        margin-right: 5px;
        font-size: 20px; }
    .upcoming-destination-list .upcoming-destination-single .content .date {
      margin-top: 8px; }

.best-package-list .best-package-single {
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .best-package-list .best-package-single:first-child {
    padding-top: 0; }
  .best-package-list .best-package-single:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .best-package-list .best-package-single .thumb {
    width: 125px; }
  .best-package-list .best-package-single .content {
    width: calc(100% - 125px);
    padding-left: 15px; }
    .best-package-list .best-package-single .content .package-name {
      text-transform: capitalize; }
    .best-package-list .best-package-single .content .place-name {
      font-size: 14px;
      display: block;
      margin-top: 6px; }
      .best-package-list .best-package-single .content .place-name i {
        font-size: 16px;
        margin-right: 3px; }
    .best-package-list .best-package-single .content .price {
      padding: 0 15px;
      color: #ffffff;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      margin-top: 10px; }
      .best-package-list .best-package-single .content .price.color-one {
        background-color: #1abc9c; }
      .best-package-list .best-package-single .content .price.color-two {
        background-color: #e67e22; }
      .best-package-list .best-package-single .content .price.color-three {
        background-color: #3498db; }

.small-post-list .small-post-single {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5; }
  .small-post-list .small-post-single:first-child {
    padding-top: 0; }
  .small-post-list .small-post-single:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .small-post-list .small-post-single .thumb {
    width: 70px; }
    .small-post-list .small-post-single .thumb img {
      height: 70px;
      object-fit: cover;
      -o-object-fit: cover;
      object-position: center;
      -o-object-position: center; }
  .small-post-list .small-post-single .content {
    width: calc(100% - 70px);
    padding-left: 15px; }
    .small-post-list .small-post-single .content .post-title a {
      font-size: 16px; }
    .small-post-list .small-post-single .content .date {
      font-size: 14px;
      margin-top: 5px;
      color: #ffffff; }

/* sidebar css end */
/* product-details-sidebar css start */
.product-licence-widget {
  padding: 30px;
  background-color: #f7f7f7;
  border: 3px solid #e6e6e6;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .product-licence-widget .nice-select {
    background-color: #ffffff; }
  .product-licence-widget p {
    font-size: 14px; }
  .product-licence-widget .form-check {
    display: flex; }
  .product-licence-widget label {
    font-size: 14px; }
  .product-licence-widget .price {
    font-family: "Josefin Sans", sans-serif;
    font-size: 24px;
    font-weight: 700; }
    .product-licence-widget .price i {
      font-size: 32px; }

.sidebar-item {
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5; }
  .sidebar-item:first-child {
    padding-top: 0; }
  .sidebar-item:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .sidebar-item p {
    word-break: break-word; }
  .sidebar-item .title {
    font-size: 16px;
    margin-bottom: 8px; }
  .sidebar-item .tags a {
    padding: 3px 10px;
    background-color: #ffffff;
    font-size: 14px;
    border: 1px solid #e5e5e5;
    color: #363636;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    margin: 4px 1px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    .sidebar-item .tags a:hover {
      background-color: #cca354;
      color: #ffffff;
      border-color: #cca354; }

/* product-details-sidebar css end */
/* footer start */
.footer {
  border-top: 2px solid rgba(204, 163, 84, 0.5);
  position: relative; }
  .footer::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.45; }
  .footer__top {
    padding-top: 100px;
    padding-bottom: 50px; }
  .footer__bottom {
    padding: 20px 0;
    background-color: #000000; }

.footer-short-menu {
  margin: -5px -10px; }
  .footer-short-menu li {
    margin: 5px 10px; }
    .footer-short-menu li a {
      color: rgba(255, 255, 255, 0.65); }

.social-link-list {
  margin: -5px; }
  .social-link-list li {
    margin: 5px; }
    .social-link-list li a {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.65); }

/* footer end */
