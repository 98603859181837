/* footer start */
.footer {
  border-top: 2px solid rgba($base-color, 0.5);
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.45; }
  &__top {
    padding-top: 100px;
    padding-bottom: 50px; }
  &__bottom {
    padding: 20px 0;
    background-color: #000000; } }
.footer-short-menu {
  margin: -5px -10px;
  li {
    margin: 5px 10px;
    a {
      color: rgba(#ffffff, 0.65); } } }
.social-link-list {
  margin: -5px;
  li {
    margin: 5px;
    a {
      font-size: 18px;
      color: rgba(#ffffff, 0.65); } } }
/* footer end */
