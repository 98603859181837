
$heading-color: #ffffff;
$para-color: #ffffff;

$base-color: #cca354;
// $base-color: #f2d586
$base-color-two: #062c4e;

// bg-color
$bg-one: #001d4a;
$bg-two: #343A40;

// border-color
$border-color: rgba($base-color,0.45);

// box-shadow
$shadow: 0px 25px 40px 0px rgba(51, 51, 51, 0.1);
$shadow-two: 0px 0px 15px 0px rgba(51, 51, 51, 0.05);
$shadow-three: 0px 25px 40px 0px rgba(51, 51, 51, 0.2);
